import React from 'react';

export default {
  register: '지금 등록하세요',
  registerNow: '지금 등록하세요',
  bookAppointment: (
    <>
      Book <br /> Appointment
    </>
  ),
  header: {
    routes: [
      {
        path: '/floorplans',
        text: '평면도',
      },
      {
        path: '/interiors',
        text: '인테리어',
      },
      {
        path: '/amenities',
        text: '편의시설',
      },
      {
        path: '/neighbourhood',
        text: '주거단지',
      },
      {
        path: '/gallery',
        text: '갤러리',
      },
      {
        path: '/developer',
        text: '개발회사',
      },
    ],
  },
  footer: {
    column1: {
      title: '문의하기',
      buttonLabel: '지금 등록하세요',
    },
    column2: {
      title: '프레젠테이션 센터',
      text: (
        <>
          매일 12 - 5PM 오픈 <br />
          (금요일 휴무)
          <br />
          <br />
          4700 Imperial Street, <br />
          Burnaby, BC
        </>
      ),
    },
    column3: {
      title: 'Site Location',
    },
    marketing: 'marketing in association with',
    disclaimer:
      '*Limited time, prices, incentives, and commissions are subject to change without notice. The 3% savings will be shown as credit on statement of adjustments upon completion. Ask a sales representative for more details. The information contained in this promotional material is for illustration purposes only and is subject to change without notice. Maps and renderings are an artistic representation only. The quality residences at Riviera are developed by LM Riviera Homes Limited Partnership. The developer reserves the right to make modifications or substitutions should they be necessary. This is currently not an offering for sale. Any such offering can only be made by way of a disclosure statement. E. & O.E.',
    corporate: {
      title: 'Confidence. Quality. Value',
      text: 'At Ledingham McAllister, we are dedicated to creating quality homes in superior locations and providing our customers with long-term value. We are committed to the highest standards in everything we do. Our past was built on it, our future depends on it.',
      privacy: 'Privacy Policy',
    },
  },
  home: {
    header: (
      <>
        중세기풍의 우아함으로, <br className="tablet" /> 고급스런 현대감각의
        라이프를 연출하세요.
      </>
    ),
    hero: {
      title:
        '세계적인 수준의 장인 정신과 세련된 중세 미학의 상징적인 통합으로 규정할 수 있는 Riviera는 Metrotown의 중심부에서 최고 수준의 도시 생활을 제공해 줍니다.',
      text: '1 베드룸 + 덴에서 3베드룸까지의 주택으로 구성된 인상적인 38층의 주거단지는 최고의 마감재와 특별한 편의 시설로 세심하게 설계되어 라이프스타일을 더욱 돋보이게 해 줍니다. 이것이 바로 Riviera on the Park입니다.',
      button: '평면도 보기',
    },
    content1: {
      title: '우아함의 결정체',
      text: '집에 들어서는 순간부터 완전히 다른 세계에 온 듯한 느낌을 받을 것입니다. 모든 마감과 자재는 섬세한 세련미와 우아함을 극대화하기 위해 신중하게 선택되었습니다. 넓고 고급스럽게 설계된 레이아웃은 입주자와 입주자 가족에게 가장 완벽한 삶을 제공하고 특별한 시간을 좋은 사람들과 함께 하기에 최고의 조건을 제시해 드립니다.',
      button: '인테리어 살펴보기',
    },
    content2: {
      title: '함께하는 라이프스타일',
      text: 'Riviera에서는 그 거주공간이 집에만 국한되지 않고 커뮤니티 전체로 확장합니다. 엘레베이터를 타면 입주자 전용 편의시설층으로 곧바로 이동하여 호텔 리조트와 같은 고급스러운 시설을 언제든 즐길 수 있습니다. 몸과 마음의 건강한 균형을 추구하든 모든 종류의 흥미진진한 엔터테인먼트를 경험하고자 하든 필요한 모든 것이 바로 여기에 있습니다!',
      button: '전용시설 살펴보기',
    },
    content3: {
      title: '평온함과 다채로움의 완벽한 조화',
      text: '아름답고 숲이 울창한 Lobley 공원에 인접해 있는 Riviera는 고요한 삶과 도시 생활을 동시에 누릴 수 있는 곳에 위치해 있으며 전국 최고의 쇼핑과 엔터테인먼트 공간 중 한 곳에서 불과 몇 걸음 떨어진 곳에 있습니다.',
      button: '주거단지 살펴보기',
    },
  },
  developer: {
    hero: {
      title: '신뢰, 품질, 그리고 가치',
      body: 'Ledingham McAllister는 최적의 위치에 탁월한 새로운 주거 커뮤니티를 조성함으로써 미래지향적이고 스마트한 개발에 전념하고 있습니다. 한 세기가 넘는 기간 동안 우리는 품질과 가치가 지속되는 주택을 보장하기 위해 엄격한 설계, 환경 리더십, 엔지니어링 및 건설 표준에 전념해 왔습니다. 20,000세대가 넘는 주택 건설 이후에 우리의 과거가 그러한 실적 위에 건설되었으며 우리의 미래가 그것에 의해 펼쳐질 것이라고 우리는 자랑스럽게 말할 수 있습니다.',
    },
    companies: {
      ibi: {
        description:
          'IBI Group 은 이제 자랑스럽게 Arcadis의 일부가 되었습니다. Arcadis Group 은 약 50년 동안 고객에게 건축, 공학, 계획, 시스템 및 다양한 기술 서비스를 제공하는 기술 중심적 설계 회사입니다. 전세계를 통틀어 총 60개 이상의 사무실과 2,700명 이상의 전문가를 거느린 이 회사는 지능적인 관행과 보다 확립된 역량을 핵심 빌딩 및 인프라 비즈니스로 이끌어냄으로써 대응력이 강하고 탄력적인 공간과 스마트한 도시 환경을 만듭니다. 고층빌딩에서 병원, 대중 교통 시스템, 학교에 이르기까지 Arcadis Group은 사람들이 미래의 도시에서 생활하고, 일하고, 움직이고, 배우고, 치유하는 방식을 건설하고자 노력합니다.',
      },
      pwl: {
        description:
          'The Mill Design Group Inc.는 2011년 인테리어 디자이너 Janine Wilson에 의해 설립되었 습니다. 아름다운 노스 밴쿠버에 기반을 둔 Mill Design Group Inc.는 다세대 주택 디자인에 매우 특화된 회사입니다. 인테리어 디자인 업계에서 18년 이상의 경험을 가진 Janine과 그녀의 놀라운 팀은 개발자들과 협력하여 미래 구매자의 미학과 가치를 구현하는 동시에 주거단지의 장점을 반영하는 인테리어 및 주택 마감을 창출해 냅니다. Mill의 디자인 신념은 우아함과 편안함을 희생하지 않은 인테리어 마무리가 시대를 초월하고 관리하기 쉬워야 한다는 것입니다.',
      },
      themill: {
        description:
          '40년 동안 PWL Partnership의 조경 건축가, 도시 디자이너 및 설계자 팀은 캐나다, 미국 및 아시아 전역에서 우수한 명성을 쌓아왔습 니다. 그들은 5명의 파트너, 4명의 어소시에이트, 30명의 조경 건축가 및 디자이너로 구성된 팀이 있습니다. 밴쿠버에 기반을 둔 PWL은 고객 그룹, 프로젝트 팀 및 지역 내 관계자와 의미 있고 지속적인 관계를 구축하기 위해 부단히 노력하고 있습니다. PWL의 경험은 해안가 복합단지, 녹지, 대중 교통 환승통로, 도시 광장, 거리 경관, 근린 공원 및 공원을 총괄합니다.',
      },
    },
    projects: [
      {
        image: require('src/assets/images/developer/projects/01_escala-burnaby.jpg'),
        text: 'Escala, Burnaby',
        small: true,
      },
      {
        image: require('src/assets/images/developer/projects/02_aviara-burnaby.jpg'),
        text: 'Aviara, Burnaby',
        small: true,
      },
      {
        image: require('src/assets/images/developer/projects/03_reflections-burnaby.jpg'),
        text: 'Reflections, Burnaby',
        small: true,
      },
      {
        image: require('src/assets/images/developer/projects/04_waterscapes-kelowna.jpg'),
        text: 'Waterscapes, Kelowna',
      },
      {
        image: require('src/assets/images/developer/projects/05_highpoint-coquitlam.jpg'),
        text: 'Highpoint, Coquitlam',
      },
      {
        image: require('src/assets/images/developer/projects/06_icon-burnaby.jpg'),
        text: 'Icon, Burnaby',
      },
      {
        image: require('src/assets/images/developer/projects/07_sydney-coquitlam.jpg'),
        text: 'Sydney, Coquitlam',
      },
      {
        image: require('src/assets/images/developer/projects/08_azure-burnaby.jpg'),
        text: 'Azure, Burnaby',
      },
      {
        image: require('src/assets/images/developer/projects/09_northgate_village-burnaby.jpg'),
        text: 'Northgate Village, Burnaby',
      },
      {
        image: require('src/assets/images/developer/projects/10_perspectives-burnaby.jpg'),
        text: 'Perspectives, Burnaby',
      },
      {
        image: require('src/assets/images/developer/projects/11_saltaire-white_rock.jpg'),
        text: 'Saltaire, White Rock',
      },
      {
        image: require('src/assets/images/developer/projects/12_dominion-new_westminster.jpg'),
        text: 'Dominion, New Westminster',
      },
    ],
  },
  form: {
    title: '지금 등록하세요.',
    firstname: '이름*',
    lastname: '성*',
    email: '이메일*',
    phone: '전화번호*',
    zip: '우편번호*',
    select: '하나를 선택해 주세요',
    howHeard: 'Riviera에 대해서 어떻게 알게 되었나요?*',
    howHeardOptions: [
      {
        label: '중국 온라인 광고',
        value: 'Chinese Online Ads',
      },
      {
        label: '중국 신문광고',
        value: 'Chinese Print Ads',
      },
      {
        label: '커뮤니티 홍보물',
        value: 'Community Paper',
      },
      {
        label: '밴쿠버 조선일보',
        value: 'VanChosun',
      },
      {
        label: '부동산 밴쿠버 (한국신문)',
        value: 'Korean Real Estate Weekly',
      },
      {
        label: '페이스북 / 인스타그램',
        value: 'Facebook / Instagram',
      },
      {
        label: '구글 검색',
        value: 'Google Search',
      },
      {
        label: 'Ledmac 이메일',
        value: 'Ledmac Email',
      },
      {
        label: 'Ledmac 웹사이트',
        value: 'Ledmac Website',
      },
      {
        label: '우편물',
        value: 'Mail Piece',
      },
      {
        label: 'MLS.ca / Realtor.ca',
        value: 'MLS.ca / Realtor.ca',
      },
      {
        label: '온라인 광고',
        value: 'Online Ads',
      },
      {
        label: '옥외 광고물',
        value: 'Outdoor Advertising',
      },
      {
        label: '리얼터',
        value: 'Realtor',
      },
      {
        label: '간판',
        value: 'Signage',
      },
      {
        label: '스카이트레인',
        value: 'Skytrain',
      },
      {
        label: '소셜 미디어',
        value: 'Social Media',
      },
      {
        label: '프로빈스 신문',
        value: 'The Province',
      },
      {
        label: '밴쿠버 선 신문',
        value: 'Vancouver Sun',
      },
      {
        label: '위챗',
        value: 'WeChat',
      },
      {
        label: '소문',
        value: 'Word of Mouth',
      },
    ],
    whatInquire: '궁금하신 사항은 무엇인가요?',
    whatInquireOptions: [
      {
        label: '1 베드룸',
        value: 'One bedroom',
      },
      {
        label: '2 베드룸',
        value: 'Two bedroom',
      },
      {
        label: '3 베드룸',
        value: 'Three bedroom',
      },
      {
        label: '펜트 하우스',
        value: 'Penthouses',
      },
    ],
    realtor: '혹시 리얼터이신가요?*',
    workingWithRealtor: '혹시 리얼터와 함께 일하시나요?',
    yes: '예',
    no: '아니요',
    ifYesRealtor: `만약 리얼터이시거나 리얼터와 함께 일하신다면 리얼터나 중개인의 이름을 적어 주세요.`,
    disclaimer:
      '향후 Ledingham McAllister가 제공하는 분양 정보, 우선 분양권및 특별 프로모션 등에 대한 정보를 이메일 등을 통하여 수신하는것에 동의합니다.*',
    submit: '제출하기',
    required: '*필수적으로 기록해야 하는 사항',
  },
  gallery: {
    hero: {
      title: '멋진 주거 공간, 감명 깊은 주변환경',
      smallStrapline:
        '생활 공간을 극대화하는 밝고 개방적인 평면도, 풀 사이즈의 고급스런 가전 제품',
    },
    interiors: {
      title: '인테리어',
      images: [
        // {
        //   src: require('src/assets/images/gallery/interiors/lightbox/living-space_light.jpg'),
        //   thumb: require('src/assets/images/gallery/interiors/thumbs/living-space_light.jpg'),
        //   alt: '거실 및 주방 - 오팔 White 테마',
        // },
        // {
        //   src: require('src/assets/images/gallery/interiors/lightbox/living-space_dark.jpg'),
        //   thumb: require('src/assets/images/gallery/interiors/thumbs/living-space_dark.jpg'),
        //   alt: '거실 및 주방 - 오닉스 Black 테마',
        // },
        // {
        //   src: require('src/assets/images/gallery/interiors/lightbox/ensuite_light.jpg'),
        //   thumb: require('src/assets/images/gallery/interiors/thumbs/ensuite_light.jpg'),
        //   alt: '마스터 베드룸 인스위트 - 오팔 White 테마',
        // },
        // {
        //   src: require('src/assets/images/gallery/interiors/lightbox/ensuite_dark.jpg'),
        //   thumb: require('src/assets/images/gallery/interiors/thumbs/ensuite_dark.jpg'),
        //   alt: '마스터 베드룸 인스위트 - 오닉스 Black 테마',
        // },
        {
          src: require('src/assets/images/gallery/interiors/lightbox/display-suite-12.jpg'),
          thumb: require('src/assets/images/gallery/interiors/thumbs/display-suite-12.jpg'),
          alt: '1 Bed | Kitchen',
        },
        {
          src: require('src/assets/images/gallery/interiors/lightbox/display-suite-05.jpg'),
          thumb: require('src/assets/images/gallery/interiors/thumbs/display-suite-05.jpg'),
          alt: '1 Bed | Integrated Refrigerator',
        },

        {
          src: require('src/assets/images/gallery/interiors/lightbox/display-suite-11.jpg'),
          thumb: require('src/assets/images/gallery/interiors/thumbs/display-suite-11.jpg'),
          alt: '1 Bed | Kitchen & Living',
        },
        {
          src: require('src/assets/images/gallery/interiors/lightbox/display-suite-13.jpg'),
          thumb: require('src/assets/images/gallery/interiors/thumbs/display-suite-13.jpg'),
          alt: '1 Bed | Living',
        },
        {
          src: require('src/assets/images/gallery/interiors/lightbox/display-suite-02.png'),
          thumb: require('src/assets/images/gallery/interiors/thumbs/display-suite-02.jpg'),
          alt: '1 Bed | Den',
        },
        {
          src: require('src/assets/images/gallery/interiors/lightbox/display-suite-01.jpg'),
          thumb: require('src/assets/images/gallery/interiors/thumbs/display-suite-01.jpg'),
          alt: '1 Bed | Main Bathroom',
        },
        {
          src: require('src/assets/images/gallery/interiors/lightbox/display-suite-04.jpg'),
          thumb: require('src/assets/images/gallery/interiors/thumbs/display-suite-04.jpg'),
          alt: '1 Bed | Main Bedroom',
        },

        {
          src: require('src/assets/images/gallery/interiors/lightbox/display-suite-16.jpg'),
          thumb: require('src/assets/images/gallery/interiors/thumbs/display-suite-16.jpg'),
          alt: '2 Bed | Kitchen',
        },
        {
          src: require('src/assets/images/gallery/interiors/lightbox/display-suite-18.png'),
          thumb: require('src/assets/images/gallery/interiors/thumbs/display-suite-18.jpg'),
          alt: '2 Bed | Kitchen',
        },
        {
          src: require('src/assets/images/gallery/interiors/lightbox/display-suite-15.jpg'),
          thumb: require('src/assets/images/gallery/interiors/thumbs/display-suite-15.jpg'),
          alt: '2 Bed | Dining & Kitchen',
        },
        {
          src: require('src/assets/images/gallery/interiors/lightbox/display-suite-17.jpg'),
          thumb: require('src/assets/images/gallery/interiors/thumbs/display-suite-17.jpg'),
          alt: '2 Bed | Kitchen & Dining',
        },
        {
          src: require('src/assets/images/gallery/interiors/lightbox/display-suite-14.jpg'),
          thumb: require('src/assets/images/gallery/interiors/thumbs/display-suite-14.jpg'),
          alt: '2 Bed | Kitchen & Living',
        },
        {
          src: require('src/assets/images/gallery/interiors/lightbox/display-suite-19.jpg'),
          thumb: require('src/assets/images/gallery/interiors/thumbs/display-suite-19.jpg'),
          alt: '2 Bed | Living',
        },
        {
          src: require('src/assets/images/gallery/interiors/lightbox/display-suite-03.png'),
          thumb: require('src/assets/images/gallery/interiors/thumbs/display-suite-03.jpg'),
          alt: '2 Bed | Main Bathroom',
        },
        {
          src: require('src/assets/images/gallery/interiors/lightbox/display-suite-08.jpg'),
          thumb: require('src/assets/images/gallery/interiors/thumbs/display-suite-08.jpg'),
          alt: '2 Bed | Main Bedroom',
        },
        {
          src: require('src/assets/images/gallery/interiors/lightbox/display-suite-06.png'),
          thumb: require('src/assets/images/gallery/interiors/thumbs/display-suite-06.jpg'),
          alt: '2 Bed | Main Bedroom Closet',
        },

        {
          src: require('src/assets/images/gallery/interiors/lightbox/display-suite-07.png'),
          thumb: require('src/assets/images/gallery/interiors/thumbs/display-suite-07.jpg'),
          alt: '2 Bed | Ensuite',
        },
        {
          src: require('src/assets/images/gallery/interiors/lightbox/display-suite-09.jpg'),
          thumb: require('src/assets/images/gallery/interiors/thumbs/display-suite-09.jpg'),
          alt: '2 Bed | 2nd Bedroom',
        },
        {
          src: require('src/assets/images/gallery/interiors/lightbox/display-suite-10.jpg'),
          thumb: require('src/assets/images/gallery/interiors/thumbs/display-suite-10.jpg'),
          alt: '2 Bed | Balcony',
        },
      ],
    },
    neighbourhood: {
      title: '주거단지',
      images: [
        {
          src: require('src/assets/images/gallery/neighbourhood/gallery_01.jpg'),
          thumb: require('src/assets/images/gallery/neighbourhood/gallery_01_thumb.jpg'),
          alt: '메트로타운',
        },
        {
          src: require('src/assets/images/gallery/neighbourhood/gallery_02.jpg'),
          thumb: require('src/assets/images/gallery/neighbourhood/gallery_02_thumb.jpg'),
          alt: '메트로폴리스',
        },
        {
          src: require('src/assets/images/gallery/neighbourhood/gallery_03.jpg'),
          thumb: require('src/assets/images/gallery/neighbourhood/gallery_03_thumb.jpg'),
          alt: '스카이트레인',
        },
        {
          src: require('src/assets/images/gallery/neighbourhood/gallery_04.jpg'),
          thumb: require('src/assets/images/gallery/neighbourhood/gallery_04_thumb.jpg'),
          alt: '센트럴 공원',
        },
        {
          src: require('src/assets/images/gallery/neighbourhood/gallery_05.jpg'),
          thumb: require('src/assets/images/gallery/neighbourhood/gallery_05_thumb.jpg'),
          alt: '디어 레이크',
        },
        {
          src: require('src/assets/images/gallery/neighbourhood/gallery_06.jpg'),
          thumb: require('src/assets/images/gallery/neighbourhood/gallery_06_thumb.jpg'),
          alt: '지역 식료품점',
        },
        {
          src: require('src/assets/images/gallery/neighbourhood/gallery_07.jpg'),
          thumb: require('src/assets/images/gallery/neighbourhood/gallery_07_thumb.jpg'),
          alt: '고급 레스토랑',
        },
        {
          src: require('src/assets/images/gallery/neighbourhood/gallery_08.jpg'),
          thumb: require('src/assets/images/gallery/neighbourhood/gallery_08_thumb.jpg'),
          alt: '럭셔리 쇼핑',
        },
        {
          src: require('src/assets/images/gallery/neighbourhood/gallery_09.jpg'),
          thumb: require('src/assets/images/gallery/neighbourhood/gallery_09_thumb.jpg'),
          alt: 'Simon Fraser University',
        },
      ],
    },
    building: {
      title: '주거 빌딩',
      images: [
        {
          src: require('src/assets/images/gallery/building/lightbox/exterior.jpg'),
          thumb: require('src/assets/images/gallery/building/thumbs/exterior.jpg'),
          alt: '건물 외부',
        },
        {
          src: require('src/assets/images/gallery/building/lightbox/entrance.jpg'),
          thumb: require('src/assets/images/gallery/building/thumbs/entrance.jpg'),
          alt: '현관 입구',
        },
        {
          src: require('src/assets/images/gallery/building/lightbox/terrace.jpg'),
          thumb: require('src/assets/images/gallery/building/thumbs/terrace.jpg'),
          alt: '테라스',
        },
        {
          src: require('src/assets/images/gallery/building/lightbox/lobby-concierge.jpg'),
          thumb: require('src/assets/images/gallery/building/thumbs/lobby-concierge.jpg'),
          alt: '로비',
        },
        {
          src: require('src/assets/images/gallery/building/lightbox/lobby-fireplace.jpg'),
          thumb: require('src/assets/images/gallery/building/thumbs/lobby-fireplace.jpg'),
          alt: '화이어플레이스',
        },
        {
          src: require('src/assets/images/gallery/building/lightbox/amenity_ping-pong.jpg'),
          thumb: require('src/assets/images/gallery/building/thumbs/amenity_ping-pong.jpg'),
          alt: '탁구대',
        },
        {
          src: require('src/assets/images/gallery/building/lightbox/amenity_kitchen.jpg'),
          thumb: require('src/assets/images/gallery/building/thumbs/amenity_kitchen.jpg'),
          alt: '주방/다이닝',
        },
        {
          src: require('src/assets/images/gallery/building/lightbox/rooftop.jpg'),
          thumb: require('src/assets/images/gallery/building/thumbs/rooftop.jpg'),
          alt: '루프 탑',
        },
      ],
    },
  },
  interiors: {
    hero: {
      title: '우아함의 결정체',
      fullStrapline:
        '집에 들어서는 순간부터 완전히 다른 세계에 온 듯한 느낌을 받을 것입니다. 넓고 고급스럽게 설계된 레이아웃은 입주자와 입주자 가족에게 가장 완벽한 삶을 제공하고 특별한 시간을 좋은 사람들과 함께 하기에 최고의 조건을 제시해 드립니다. 대형 창문이 자연 채광과 신선한 공기를 실내로 끌어들여 집 안 분위기를 밝고 상쾌하게 만들어 줍니다. 뿐만 아니라 1년 내내 실내에서도 야외 생활을 즐길 수 있는 넓은 발코니가 있습니다.',
    },
    opal: '오팔',
    onyx: '오닉스',
    opalOnyx: {
      content2: {
        title: '입주자의 취향에 따른 맞춤 공간',
        body: '모든 마감과 자재는 섬세한 세련미와 우아함을 극대화하기 위해 신중하게 선택되었습니다. The Mill Design Group에서 선별한 두 가지 색조와 텍스쳐 팔레트 - 따뜻하고 활기찬 오팔 또는 시원하고 우아한 오닉스 (Black) -를 선택하여 입주자의 개인적인 취향에 맞춘 스타일 감각을 반영하고 세련미를 창출할 수 있습니다.',
      },
    },
    features: {
      title: '지능적인 설계',
      column1: [
        '넓고 고급스러운 워터폴 스톤 아일랜드는 모든 종류의 음식을 준비하기에 충분히 넓은 공간을 제공해 줍니다.',
        '식료품 저장실을 포함하는 전체 높이 캐비닛은 세련된 석영 카운터탑과 매끄러운 백 스플래쉬와 짝을 이루어 세련미를 더해 줍니다.',
        '5구 Bosch 가스 쿡탑과 내장형 Fisher & Paykel 프렌치 2도어 냉장고가 포함된 셰프급 풀 사이즈 가전 제품 패키지',
      ],
      column2: [
        '풀 사이즈, 프론트 로딩, 에너지 효율적인 Whirlpool 세탁기 및 건조기',
        '스파에서 영감을 받은 욕실은 하단에 설치된 부드러운 조명이 있는 아름운 플로팅 화장대와 맞춤형 화장대 거울 캐비닛이 포함됩니다.',
        '프레임이 없는 유리 샤워 부스, 또는 넓고 깊은 욕조가 욕실의 편안한 분위기를 더해 줍니다.',
      ],
      download: '상세 설명서 다운로드하기',
    },
  },
  amenities: {
    hero: {
      title: '입구에서 부터 느끼는 고급스러움',
      fullStrapline:
        '수상 경력에 빛나는 IBI Architects가 세심하게 설계한 Riviera는 첫눈에 매우 인상적입니다. 단지의 웅장한 입구에 들어서면 가장 먼저 눈을 사로잡는 아름다운 분수 조경과 금빛 격자 모양이 한층 더 돋보이는 분위기를 연출해 줍니다.  편리한 호텔 분위기의 자동차 대기 공간은 방문자 또는 소포 등의 배달을 위해 도착 및 픽업을 용이하게 해줍니다. 웅장한 리셉션 홀에 들어서면 우아한 초대형 샹들리에가 따스한 조명을 비추고 리셉션 데스크의 컨시어지의 따뜻한 환대가 입주자는 물론 방문하는 모든 사람들에게 중세 귀족처럼 대우받는 느낌을 갖게 해 줄 것입니다.',
    },
    content1: {
      title: '함께하는 라이프스타일',
      body: '엘레베이터를 타면 입주자 전용 편의시설 층으로 곧바로 이동하여 호텔 리조트와 같은 고급스러운 시설을 언제든 즐기고, 삶을 더 다채롭게 누릴 수 있게 합니다. 시설이 완비된 피트니스 센터에서 언제든 편리하게 운동할 수 있으며, 방문하는 가족 및 친지들이 집과 같은 편안함을 느낄 수 있는 여러 게스트 스위트가 있습니다. 또한 Riviera Social Lounge공간도 프라이빗 클럽과 같은 고급 시설로 갖추어져 있습니다.',
    },
    content2: {
      title: '태양과 별빛 아래에서',
      body: '바로 밖에는 아름답게 조성된 울창한 조경의 야외 테라스에는 화덕과 함께 좌석 공간이 있습니다. 우아하고 편안하며 식사 또는 바베큐를 즐기기에 최적의 장소로써 모든 종류의 모임을 위한 충분한 공간입니다.',
    },
    levels: {
      title: '고품격 삶을 누릴 수 있는 이 곳으로 당신을 초대합니다.',
      button1: 'Level 1',
      button2: 'Level 7',
    },
  },
  neighbourhood: {
    hero: {
      title: '평온함과 다채로움의 완벽한 조화',
      fullStrapline:
        'Burnaby에서는 마음 속으로 상상하는 모든 것들을 바로 경험할 수 있습니다. 다양한 레스토랑과 바, 전문점, 클럽, 자연 속 공원, 어린이 놀이 공간, 쇼핑 단지 그리고 그 외의 시설들이 즐비합니다. 소핑에서부터 댄스 교실까지, 카페에서부터 수제 칵테일까지 이 모든 것들이 도보 거리에 위치해 있습니다.',
    },
    content1: {
      title: 'Metrotown에서 삶을 더 풍성하게',
      text: 'Metrotown의 Metropolis를 이용하면 다운타운에 가지 않고도 도심의 모든 편의를 완벽하게 즐길 수 있습니다. Riviera는 300개 이상의 상점에서 제공하는 서비스를 일주일 내내 언제든 편안하게 즐길 수 있는 쇼핑센터가 도보로 불과 몇 분 거리에 위치해 있습니다.',
    },
    content2: {
      title: '어느 곳이든 편리하게',
      text: 'Riviera는5분 이내 거리에 2개의 스카이트레인 역이 있으며 다양한 버스 노선이 제공하는 편리함을 누릴 수 있습니다. 밴쿠버 시내, 밴쿠버 국제공항은 물론 SFU 및 BCIT 등과 같은 학교까지 매우 쉽고 편리하게 이동할 수 있습니다!',
    },
    select: 'Select a Category',
    map: [
      {
        title: '레스토랑',
        list: [
          {
            name: 'Trattoria',
            top: '19.74%',
            left: '46.19%',
          },
          {
            name: 'Earls',
            top: '30.99%',
            left: '48.77%',
          },
          {
            name: 'Cactus Club',
            top: '37.82%',
            left: '47.42%',
          },
          {
            name: 'Chipotle',
            top: '47.97%',
            left: '55.89%',
          },
          {
            name: 'Mon Paris Patisserie',
            top: '44.09%',
            left: '40.04%',
          },
          {
            name: 'Sushi Garden',
            top: '24.72%',
            left: '52.70%',
          },
          {
            name: 'Marutama Ramen',
            top: '51.84%',
            left: '81.69%',
          },
          {
            name: "Nando's",
            top: '45.75%',
            left: '64.61%',
          },
          {
            name: 'Starbucks',
            top: '33.39%',
            left: '64.98%',
          },
          {
            name: 'La Forêt',
            top: '69.00%',
            left: '64.12%',
          },
          {
            name: 'Fondway Cafe',
            top: '55.53%',
            left: '50.36%',
          },
          {
            name: 'Jenjudan',
            top: '11.99%',
            left: '2.45%',
          },
          {
            name: 'Boiling Point',
            top: '56.27%',
            left: '87.46%',
          },
          {
            name: 'ZUBU',
            top: '52.58%',
            left: '56.26%',
          },
          {
            name: 'Bella Gelateria',
            top: '44.83%',
            left: '58.35%',
          },
          {
            name: 'The Dolar Shop',
            top: '35.05%',
            left: '45.08%',
          },
        ],
      },
      {
        title: '레크레이션',
        list: [
          {
            name: 'Bonsor Recreation Complex',
            top: '55.53%',
            left: '62.89%',
          },
          {
            name: 'Cineplex Cinemas Metropolis',
            top: '35.97%',
            left: '60.44%',
          },
          {
            name: 'Central Park',
            top: '39.66%',
            left: '15.23%',
          },
          {
            name: 'Central Park Public Tennis Courts',
            top: '32.28%',
            left: '22.97%',
          },
          {
            name: 'Central Park Pitch + Putt',
            top: '61.99%',
            left: '14.74%',
          },
          {
            name: 'Bonsor Park',
            top: '60.88%',
            left: '65.35%',
          },
          {
            name: 'Kinnee Park',
            top: '49.44%',
            left: '27.88%',
          },
          {
            name: 'Maywood Park',
            top: '58.30%',
            left: '34.27%',
          },
          {
            name: 'GoodLife Fitness',
            top: '20.84%',
            left: '47.54%',
          },
          {
            name: 'Fitness World',
            top: '57.38%',
            left: '89.06%',
          },
          {
            name: 'Anytime Fitness',
            top: '84.68%',
            left: '81.69%',
          },
          {
            name: 'David Gray Off-Leash Park',
            top: '84.68%',
            left: '35.50%',
          },
        ],
      },
      {
        title: '쇼핑 & 각종 서비스',
        list: [
          {
            name: 'Metropolis at Metrotown',
            top: '37.82%',
            left: '54.66%',
          },
          {
            name: 'Crystal Mall',
            top: '26.56%',
            left: '44.34%',
          },
          {
            name: "Hudson's Bay",
            top: '43.91%',
            left: '66.58%',
          },
          {
            name: 'ICBC',
            top: '45.94%',
            left: '62.89%',
          },
          {
            name: 'Pharmasave',
            top: '43.91%',
            left: '38.94%',
          },
          {
            name: 'PriceSmart Foods',
            top: '29.33%',
            left: '50.73%',
          },
          {
            name: 'RBC',
            top: '19.37%',
            left: '37.71%',
          },
          {
            name: 'Real Canadian Superstore',
            top: '33.39%',
            left: '52.82%',
          },
          {
            name: 'Walmart',
            top: '46.67%',
            left: '53.80%',
          },
          {
            name: 'Save-On-Foods',
            top: '14.94%',
            left: '44.34%',
          },
          {
            name: 'Rexall',
            top: '40.22%',
            left: '45.08%',
          },
          {
            name: 'Scotiabank',
            top: '13.09%',
            left: '36.48%',
          },
          {
            name: 'Shoppers Drug Mart',
            top: '32.65%',
            left: '64.12%',
          },
          {
            name: 'T&T Supermarket',
            top: '44.46%',
            left: '50.98%',
          },
          {
            name: 'TD Bank',
            top: '27.30%',
            left: '48.28%',
          },
          {
            name: 'HSBC',
            top: '23.06%',
            left: '45.82%',
          },
          {
            name: 'Vancity',
            top: '37.82%',
            left: '71.74%',
          },
          {
            name: 'Hilton Burnaby',
            top: '30.25%',
            left: '45.82%',
          },
          {
            name: 'Best Buy',
            top: '35.23%',
            left: '45.08%',
          },
          {
            name: 'BC Liquor Store',
            top: '13.09%',
            left: '41.40%',
          },
          {
            name: 'London Drugs',
            top: '40.77%',
            left: '69.28%',
          },
          {
            name: 'Burnaby Public Library',
            top: '34.50%',
            left: '40.66%',
          },
          {
            name: 'WeWork',
            top: '33.39%',
            left: '48.28%',
          },
        ],
      },
      {
        title: '학교',
        list: [
          {
            name: 'Marlborough Elementary',
            top: '28.41%',
            left: '72.60%',
          },
          {
            name: 'Maywood Community School',
            top: '61.25%',
            left: '54.66%',
          },
          {
            name: 'Burnaby South Secondary',
            top: '91.51%',
            left: '95.82%',
          },
          {
            name: 'South Slope Elementary',
            top: '84.68%',
            left: '42.50%',
          },
          {
            name: 'Wonderworld Montessori Academy',
            top: '45.20%',
            left: '41.03%',
          },
          {
            name: 'CEFA Early Learning',
            top: '4.98%',
            left: '19.16%',
          },
          {
            name: 'Suncrest Elementary',
            top: '91.51%',
            left: '19.16%',
          },
          {
            name: 'Nelson Elementary',
            top: '91.51%',
            left: '63.51%',
          },
        ],
      },
    ],
  },
  floorplans: {
    title: (
      <>
        중세기의 우아함이 살아있는 1베드룸+덴 부터 <br /> 3베드룸까지의 주거단지
      </>
    ),
    plan: '플랜',
    type: '타입',
    size: '면적',
    download: 'Download Plan',
    disclaimer:
      'In a continuing effort to meet the challenge of product improvements, we reserve the right to modify or change dimensions, sizes, specifications, layouts, plan orientations and materials without notice. All patio, terrace and balcony sizes may vary. Windows, columns, privacy screens, demising walls, spandrel and guardrail details may vary depending upon the plan and floor level. The quality of homes at Riviera are built by LM Riviera Homes Limited Partnership. E.&O.E.',
    views: {
      title: <>이상적인 주거지역의 360도 전망</>,
      drag: '드래그하여 자세한 전망 보기',
    },
  },
  privacy: {
    title: 'Privacy Policy',
    body: (
      <>
        <p>
          This Privacy Policy has been prepared by Ledingham McAllister for
          their required site (herein known as “Riviera”, “we”, “us”, “our”) and
          sets out the way Ledingham McAllister collects, discloses, use, and
          otherwise manages personal information.
          <br />
          <br />
          The Privacy Policy also describes the privacy practices on the
          Ledingham McAllister required site website (the “website”, the “site”,
          or the “websites”) and through other interactions with consumers.
        </p>

        <h3>Collection and Use of Personal Information</h3>
        <p>
          <span>Guest Registry:</span> When you visit one of our sales centres
          and complete a guest registry form, we collect contact information
          such as your first and last name, your mailing address, your email
          address, how you heard about us and your personal phone numbers. You
          may also choose to provide optional additional information such as
          your current housing situation, your reasons for moving, where you
          currently reside, your desired housing characteristics, family status,
          age group, number of adults and children and your annual household
          income.
          <br />
          <br />
          We only use this information that you have provided on the Guest
          Registry to form a better understanding of your needs, aid you in
          finding a suitable home and to send you tailored communications about
          our developments, products and services.
          <br />
          <br />
          We also use this information on an aggregator basis to help us better
          understand our customers and to improve our products and service
          offerings.
          <br />
          <br />
          <span>Buying a Home:</span> When you complete a CPS (Contract of
          Purchase and Sale) for a residential property through Ledingham
          McAllister at any of our developments, we will collect the information
          you provided on the Agreement including the first and name and contact
          information (such as telephone numbers and mailing address) for the
          purchasers, including the property description, and purchase amount.
          We utilize this information for the purposes of facilitating the
          residential property transaction.
          <br />
          <br />
          Additionally, we will collect pertinent information to verify the
          identity of each purchaser (such as a valid government-issued piece of
          identification) as well as a personal cheque for deposit purposes and
          as required by law, REDMA and Fintrac rules, regulations and policies.
          <br />
          <br />
          <span>Warranty Registration and Requests:</span> We collect certain
          pieces of personal information, including your name, email address,
          mailing address, telephone numbers and property closing date when you
          register for a new home warranty plan in your respective and
          applicable province or when you make a warranty customer care request.
          This information will be used to complete the warranty registration
          and to fulfill your warranty request.
          <br />
          <br />
          <span>Website Registration:</span> In order to obtain access to
          information regarding products and services on any password-protected
          areas of our websites, we may request certain personal information
          such as your name, telephone number, mailing address, email address
          and the corresponding password that you have selected. We use this
          information to provide and administer your online account or service
          your request.
          <br />
          <br />
          <span>Marketing Communications:</span> When you complete and submit a
          guest registration form, or otherwise sign-up to receive information
          regarding our developments or related products and services, we
          collect your contact information such as your name, phone numbers,
          mailing addresses and email address. We will use this information to
          send you communications based on your expressed interests by mail,
          email or telephone. You may opt-out of receiving all future marketing
          and promotional communications at any time by clicking on the
          unsubscribe link included in our email communications, or by
          contacting us with the credentials noted at the bottom of this Privacy
          Policy.
          <br />
          <br />
          <span>Customer Service:</span> Any time that you contact us with a
          comment, complaint or a question, you may be asked for information to
          help us identify yourself (such as your name, address, and your
          telephone number) along with additional information we may need to
          help us promptly answer your question or respond to your comment or
          complaint (e.g: your lot number, warranty enrolment number,
          development, community, etc.) We may retain this information to assist
          you in the future and to improve our customer service and product and
          service offerings. We may additionally use personal information to
          establish and manage our relationship with you and provide quality
          customer service.
        </p>
        <h3>Disclosure and Sharing of Your Personal Information</h3>
        <p>
          We will not under any circumstances disclose, rent, trade, sell or
          otherwise transfer your personal information without your consent,
          except as otherwise outlined herein.
          <br />
          <br />
          <span>Service Providers:</span> Your personal information may be
          transferred (or otherwise made available) to our designated third
          parties or affiliates who provide services on our behalf. As an
          example, we may utilize a service provider for the maintenance of our
          website, including hosting an information form, providing additional
          services related to our site, sending electronic mail or other
          functions related to our business or services provided.
          <br />
          <br />
          Our service providers are given only the information they need to
          perform designated functions, and are not authorized under any
          circumstances to disclose personal information for their own marketing
          purposes.
          <br />
          <br />
          Your personal information may be maintained and processed by us, our
          affiliations and other third-party service providers in Canada, the US
          or other foreign jurisdictions. In the event that personal information
          is transferred to other foreign jurisdictions, it will be subject to
          the laws of that country and may be disclosed to or accessed by their
          respective courts of law (or related parties), local law enforcement
          and governmental authorities in accordance to their laws.
          <br />
          <br />
          <span>Legal/Compliance:</span> Ledingham McAllister, its affiliates,
          sister companies and its Canadian or foreign service providers may
          provide your personal information in response to a search warrant or
          other legally valid order or inquiry to another organization for the
          purposes of investigating any breaches of an agreement, violation of a
          law or detecting, preventing or suppressing fraud, or as otherwise
          permitted or required by applicable Canadian or other foreign laws.
          Your personal information may also be disclosed where necessary for
          the establishment, exercise or defense of legal claims and to prevent
          or investigate loss or harm to persons or property.
          <br />
          <br />
          <span>Sale of Business:</span> Personal information may be provided to
          third parties in connection with a prospective or completed business
          transaction, including a sale or merger (including transfers made as
          part of bankruptcy proceedings or insolvency) involving all or a part
          of Ledingham McAllister or as a part of a corporate reorganization or
          stock sale or additional or other changes in corporate control.
        </p>
        <h3>
          Internet-Based Advertising, Information About our Website and Cookies
        </h3>
        <h4>OVERVIEW</h4>
        <p>
          We and/or our service providers collect IP (Internet protocol)
          addresses from all visitors to the website and other related
          information such as browser type, operating system, page requests and
          an average time spent visiting our website.
          <br />
          <br />
          This information is only used to enable us to understand our site’s
          activity and to improve and monitor the website.
        </p>
        <h4>TRACKING TAGS, WEB BEACONS AND COOKIES</h4>
        <p>
          Our site uses a technology called ’web beacons’ and ‘tracking tags’.
          These systems allow us to understand which pages you frequent on our
          site. Tracer tags like these are utilized to help us learn how we can
          tailor and optimize our website for yourself and other site visitors.
          <br />
          <br />
          Our website utilizes a technology known as ‘cookies.’ Cookies are
          essentially pieces of data that sit on your hard drive so we can
          recognize you again when you return to our site.
          <br />
          <br />
          We utilize cookies on the sections of our site in which you are
          prompted to log in or have boxes which are customizable. In the event
          that you have registered on our site, these cookies will allow us to
          identify who you are and which browser you are using and will allow us
          to recognize that you have previously visited the site and it is may
          be necessary to access your account information (which is
          automatically stored on our computers) in an effort to personalize
          services and deliver products.
          <br />
          <br />
          Cookies provide us and our service providers with information
          pertaining to your utilization of our site (e.g: which sections of the
          site you have frequented) that will then be used to personalize our
          site in accordance with your designated interests and preferences.
          <br />
          <br />
          Our site is not designed to respond to ‘do not track’ signals that are
          received from browsers.
        </p>
        <h4>INTERNET-BASED ADVERTISING</h4>
        <p>
          You may find our advertisements on other websites through the usage of
          our third parties, such as Facebook. These companies may additionally
          use web beacons, tracer tags, cookies to report understand certain
          information surrounding your visits to our site and other sites you
          visit and/or interact to ads, in order to measure the effectiveness of
          our marketing campaigns and to deliver the appropriate ads which are
          relevant to you on and off our website.
          <br />
          <br />
          If you would like to learn additional information about the collection
          and use of information by third parties for behavioural purpose.
          Additionally, if you would like to opt-out of certain third-party
          cookies or selection what online advertisements you choose to receive,
          you may do so by visiting the Digital Advertising Alliance of Canada
          website.
          <br />
          <br />
          If you would like opt-out of internet-based ads from Google, you may
          do so here.
        </p>
        <h4>ANALYTICS</h4>
        <p>
          We may utilize services from a third party such as Google Analytics to
          aid us in obtaining and analyzing information about the sections of
          our site which you frequent. These third parties may use technologies
          as noted above including: web beacons, web server logs and cookies.
          This information will be used to improve and evaluate your customer
          experience and the simplicity and convenience of our website, in
          addition to aiding us in understanding the specific ways in which you
          use our site based on your website visits. If you would like further
          information regarding how Google uses data when on a patterns’ sites
          or applications, you may do so here.
        </p>
        <h4>GOOGLE ANALYTICS</h4>
        <p>
          Google Analytics is a web analytics service offered by Google that
          tracks and reports website traffic. Google uses the data collected to
          track and monitor the use of our service. This data is shared with
          other Google services. Google may use the collected data to
          contextualize and personalize the ads of its own advertising network.
          <br />
          <br />
          You can opt-out of having made your activity on the service available
          to Google Analytics by installing the Google Analytics opt-out browser
          add-on. The add-on prevents the Google Analytics JavaScript (ga.js,
          analytics.js, and dc.js) from sharing information with Google
          Analytics about visits activity.  <br />
          <br />
          For more information on the privacy practices of Google, please visit
          the Google Privacy & Terms web
          page: https://policies.google.com/privacy?hl=en
        </p>
        <h4>FACEBOOK ADVERTISEMENTS</h4>
        <p>
          Facebook offers advertising that targets users based on designed
          audiences and specific objectives. Facebook collects this data based
          on provided and approved user parameters.
          <br />
          <br />
          Alternatively, ads may target you based off of data from your browser
          through the utilization of algorithms. Facebook may use the collected
          data to contextualize and personalize the ads of its own advertising
          network.
          <br />
          <br />
          You can opt-out of ads by going to your Facebook profile, selecting
          settings, ad, and then making modification in the Ad settings (Ads
          based on data from partners) by selecting ‘Not Allowed.’
        </p>
        <h4>SOCIAL MEDIA</h4>
        <p>
          This site may provide you with the opportunity to engage with our
          content on or through third-party social networking sites,
          applications or plug-ins. When you engage with our content on or
          through third-party operated social networking websites, applications
          or plug-ins, you may allow us to have access to certain information
          that is associated with your social media accounts (Username, email,
          name) to deliver the content or as a part of the operation of the
          website, application or plug-in. When you provide information from
          your social media account, we may utilize this information to
          personalize your experience on our site and on the third-party social
          networking websites, applications or plug-ins and to provide you with
          other services or products you may request.
        </p>
        <h4>THIRD PARTY LINKS</h4>
        <p>
          Our site may contain links to other sites that are not owned or
          operated by us, this includes social media websites. The links to our
          website may be featured on third party websites on which we advertise
          on. Except as you provided herein, we will not under any circumstances
          provide any of your personal information to these third parties
          without your explicit consent. These links are provided to third party
          websites as a convenience to you as the site user. Links are not
          intended to act as an endorsement of or a referral to the linked
          sites. These linked sites have independent and separate privacy
          statements, terms of use and notices which we advise you to read very
          carefully. We do not have any control over these websites, and as
          such, have no liability or responsible for the manner in which these
          organizations that operate these linked websites may use, disclose,
          collect, secure or otherwise treat your personal information.
        </p>
        <h3>Retention and Safeguards</h3>
        <p>
          We have applied reasonable administrative, technical and physical
          measures in an effort to protect the personal information in our
          custody and control against loss, theft and unauthorized access
          including the usage, modification and disclosure of information. We
          restrict access to your personal information on a need to know basis
          to employees and authorized service providers who need access in order
          to fulfill their job requirements.
          <br />
          <br />
          Your online access to your personal information may be protected with
          a password that you have selected. We strongly discourage you from
          disclosing or sharing your password with anyone. We will never prompt
          you for your password via any unsolicited communication (such as phone
          calls, email, phone calls, or social media messaging systems).
          <br />
          <br />
          Our personal information retention processes are meant to retain
          personal information of our customers for no longer than necessary for
          the purposes stated above or to otherwise adhere to legal parameters.
        </p>
        <h4>ACESS TO YOUR PERSONAL INFORMATION</h4>
        <p>
          You retain the right to access, update and correct inaccuracies in
          your personal information that we have in our custody and control.
          This is subject to personal exceptions as prescribed by the law.
          <br />
          <br />
          You may request access to update, modify and correct inaccuracies in
          personal information that have in our possession or control by
          emailing, writing us, or calling us through the contact information as
          noted below.
          <br />
          <br />
          We may in turn request certain information for verification purposes
          in order to properly identify you as the appropriate person seeking
          access to their personal information records.
        </p>
        <h4>CHANGES TO THE PRIVACY POLICY</h4>
        <p>
          This privacy policy may be updated periodically to reflect changes to
          our personal information practices in accordance with the law. We will
          post the updated Privacy Policy on our website; your personal
          information will always be treated in accordance to what is noted
          within the Privacy Policy in place at the time your personal
          information was collected, unless you otherwise consent.
        </p>
        <h4>CONTACT US</h4>
        <p>
          If you have any questions about this Privacy Policy, please contact
          us:
          <br />
          <br />
          By email:{' '}
          <a href="mailto:admin@rivierabyledmac.com">
            admin@rivierabyledmac.com
          </a>
          <br />
          By visiting this page on our website:{' '}
          <a
            href="https://rivierabyledmac.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://rivierabyledmac.com/
          </a>
          <br />
          By phone number: <a href="tel:778-658-0288">778-658-0288</a>
          <br />
        </p>
      </>
    ),
  },
  notFound: {
    title: (
      <>
        Sorry! <br />
        We Apologize
      </>
    ),
    strapline: '404 Not Found',
    body: (
      <>
        Looks like something went wrong here. <br />
        The page you are looking for does not exist.
      </>
    ),
    button: 'Back to Homepage',
  },
  thankYou: {
    title: 'Thank You',
    body: (
      <>
        Thank you for registering for Riviera by Ledingham McAllister. <br />
        Your submission has been received and a representative will be in touch
        with you soon.
      </>
    ),
    button: 'Back to Homepage',
  },
};
